import React, { FC } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  text-align: center;
`

const ImgBox = styled.div`
  display: block;
  position: relative;
`

const LinkButton = styled(Link)`
  position: absolute;
  left: 50%;
  top: 75%;
  transform: translate(-50%, -50%);
  display: block;
  box-sizing: border-box;
  background: ${({ theme }) => theme.colors.blue};
  border-radius: 10px;
  padding: 15px;
  text-align: center;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  transition: 0.3s;
  text-decoration: none;
  &:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  ${({ theme }) => theme.media.lm`
    padding: 8px;
    font-size: 0.85rem;
    width: 200px;
  `}
`

const TopImg: FC = () => {
  return (
    <Wrapper>
      <ImgBox>
        <StaticImage
          src="../../../static/images/top-image.jpg"
          alt="トップ画像"
          width={800}
        />
        <LinkButton to="/about">Toursimプロジェクトとは？</LinkButton>
      </ImgBox>
    </Wrapper>
  )
}

export default TopImg
