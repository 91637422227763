import React, { FC } from "react"
import styled from "styled-components"
import Head from "~/components/Head"
import Layout from "~/components/Layout"
import TopImg from "~/components/IndexPage/TopImg"
import Links from "~/components/IndexPage/Links"
import News from "~/components/IndexPage/News"
import AboutLink from "~/components/IndexPage/AboutLink"

const Wrapper = styled.div`
  margin: 30px;
`

const IndexPage: FC = () => {
  return (
    <>
      <Head />
      <Layout>
        <Wrapper>
          <TopImg />
          <Links />
          <News />
          <AboutLink />
        </Wrapper>
      </Layout>
    </>
  )
}

export default IndexPage
