import React, { FC } from "react"
import styled from "styled-components"
import ExternalLink from "~/components/ExternalLink"
import TagLabel from "~/components/TagLabel"

type Props = {
  link?: string
  url: string
  date: string
  desc: string
  tags: string[]
}

const Card = styled.div`
  width: 250px;
  margin: 10px;
  border: 3px solid #aaa;
  border-radius: 10px;
  padding: 10px;
`

const Details = styled.div`
  margin: 10px;
`

const Text = styled.div`
  margin: 5px 0;
`

const LabelBox = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const NewsCard: FC<Props> = ({ link, url, date, desc, tags }) => {
  return (
    <ExternalLink link={link ? link : "/"}>
      <Card>
        <img src={url} alt="ニュース画像" width={250} />
        <Details>
          <Text>{date}</Text>
          <Text>{desc}</Text>
        </Details>
        <LabelBox>
          {tags && tags.map(tag => <TagLabel tech={tag} key={tag} />)}
        </LabelBox>
      </Card>
    </ExternalLink>
  )
}

export default NewsCard
