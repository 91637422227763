import React, { FC } from "react"
import styled from "styled-components"

const Button = styled.a`
  background: ${({ theme }) => theme.colors.blue};
  color: #fff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  font-size: 1.5rem;
  text-decoration: none;
  word-break: keep-all;
  &:hover {
    opacity: 0.7;
  }
  ${({ theme }) => theme.media.lm`
    font-size: 0.85rem;
  `}
`

type Props = {
  link: string
  children: React.ReactNode
}

const FormLinkButton: FC<Props> = ({ link, children }) => {
  return (
    <Button href={link} target="_blank">
      {children}
    </Button>
  )
}

export default FormLinkButton
