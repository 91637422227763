import React, { FC } from "react"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import dateFormat from "dateformat"
import InternalLink from "~/components/InternalLink"
import NewsCard from "~/components/NewsCard"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"

const Wrapper = styled.div`
  margin: 50px 0;
  ${({ theme }) => theme.media.lm`
    margin: 0;
  `}
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
  font-size: 1.5rem;
`

const Label = styled.div`
  display: inline-block;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.colors.yellow};
  color: black;
  border-radius: 5px;
`

const ArrowRight = styled(ArrowRightAltIcon)`
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.purple};
`

const NewsBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const News: FC = () => {
  const data = useStaticQuery<GatsbyTypes.ThreeNewsQuery>(query)

  return (
    <Wrapper>
      <Header>
        <Label>News</Label>
        <InternalLink link="/news">
          <span>もっと見る</span>
          <ArrowRight sx={{ fontSize: 40 }} />
        </InternalLink>
      </Header>
      <NewsBox>
        {data.cms.someNews.map(news => (
          <NewsCard
            key={news.id}
            link={news.link}
            url={news.image.url}
            date={dateFormat(
              news.customDate ? news.customDate : news.createdAt,
              "isoDate"
            )}
            desc={news.title}
            tags={news.tag}
          />
        ))}
      </NewsBox>
    </Wrapper>
  )
}

export const query = graphql`
  query ThreeNews {
    cms {
      someNews(orderBy: createdAt_DESC, first: 3) {
        id
        title
        image {
          url
        }
        link
        tag
        createdAt
        customDate
      }
    }
  }
`

export default News
