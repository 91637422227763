import React, { FC } from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import InternalLink from "~/components/InternalLink"

const Wrapper = styled.div`
  text-align: center;
  margin: 100px 0;
`

const AboutLink: FC = () => {
  return (
    <Wrapper>
      <InternalLink link="/about">
        <StaticImage
          src="../../../static/images/about-link.jpg"
          alt="Tourismプロジェクトってなに？"
          width={700}
          style={{ borderRadius: 50 }}
        />
      </InternalLink>
    </Wrapper>
  )
}

export default AboutLink
