import React, { FC } from "react"
import FormLinkButton from "~/components/FormLinkButton"
import styled from "styled-components"

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  width: 400px;
  margin: 0 auto;
  ${({ theme }) => theme.media.lm`
    width: auto;
    margin: 0;
  `}
`

const Links: FC = () => {
  return (
    <Wrapper>
      <FormLinkButton link="https://forms.gle/v1oipudDwDD2NSNa8">
        個別説明会エントリー
      </FormLinkButton>
      <FormLinkButton link="https://forms.gle/RH9aL39FJo8NCipH6">
        プロジェクト参加エントリー
      </FormLinkButton>
    </Wrapper>
  )
}

export default Links
